<template>
  <div
    v-if="!score.deleted || isAdmin"
    class="card"
    :class="{
      '!bg-gray-300': isAdmin && scoreInfo && scoreInfo.originalTargetPerson,
    }"
  >
    <div class="card-content">
      <article class="score-media">
        <img
          style="grid-area: pic"
          class="image is-64x64 image-rounded"
          v-lazy-load :data-src="imageSrc"
          @error="noImage"
        />
        <div class="content has-no-margin-bottom" style="grid-area: comment">
          <div class="flex items-center" style="flex-wrap: wrap">
            <b-tooltip
              v-if="isAdmin && scoreInfo && scoreInfo.customer"
              multilined
              dashed
            >
              <template #content>
                <b v-if="scoreInfo.customer?.phoneNumber">
                  Mobile:
                  {{
                    $helpers.formatPhoneNumber(scoreInfo.customer.phoneNumber)
                  }}
                </b>
                <br />
                Email: {{ scoreInfo.customer.email }}
              </template>
              <a
                v-if="scoreInfo.tenderId"
                class="score-title text-xl mr-2"
                :href="`${$helpers.phpDomain}/operator/tender/id/${scoreInfo.tenderId}`"
                target="_blank"
              >
                {{ customerName }}
              </a>
              <b v-else class="score-title text-xl mr-2">
                {{ customerName }}
              </b>
            </b-tooltip>
            <b v-else class="score-title text-xl mr-2">
              {{ customerName }}
            </b>
          </div>
          <span v-if="score.profession" class="font-bold">
            {{ score.profession.name }} ·
          </span>
          <time class="font-bold">
            {{
              $d(
                new Date(score.created),
                isAdmin ? "numericDateTime" : "numericDate"
              )
            }}
          </time>
          <div v-if="isAdmin && scoreInfo && scoreInfo.originalTargetPerson">
            <NuxtLink
              class="text-info hover:text-info-darker underline"
              :to="
                localePath({
                  name: 'professionalPage',
                  params: {
                    professionalSeoName: scoreInfo.originalTargetPerson.seoName,
                  },
                })
              "
            >
              {{ $t("scoreCard.originalScoreTarget") }}
            </NuxtLink>
          </div>
          <span class="score-what" v-text="score.what" />
          <article v-if="score.answer" class="message is-info">
            <div class="message-body score-answer">
              <h6>
                {{ $t("scoreCard.mateReply") }}
              </h6>
              <span class="score-reply" v-text="score.answer.answer" />
            </div>
          </article>
        </div>
        <div class="score-right" style="grid-area: rating">
          <b-rate icon-pack="fas" :value="score.score / 2" :disabled="true" />
          <div v-if="isAdmin" class="flex flex-col gap-1">
            <p v-if="score.deleted" class="text-error font-bold">
              {{ $t("scoreCard.deleted") }}
            </p>
            <JoszakiButton
              v-if="score.deleted"
              type="warning"
              :loading="restoring"
              @click="restoreScore"
            >
              {{ $t("scoreCard.restore") }}
            </JoszakiButton>
            <JoszakiButton
              v-else
              type="error"
              :loading="deleting"
              @click="deleteScore"
            >
              {{ $t("common.delete") }}
            </JoszakiButton>
            <JoszakiButton
              :to="
                localePath({
                  name: 'conductorRatingsEditPage',
                  params: { id: score.id },
                })
              "
              target="_blank"
            >
              {{ $t("common.edit") }}
            </JoszakiButton>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import defaultCustomerUrl from "@/assets/default-avatar1.jpg";

import { useAdminProfessionalStore } from "~/stores/admin/professional";
import { useUserStore } from "~/stores/user";

export default {
  props: {
    score: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      adminProfessionalStore: useAdminProfessionalStore(),
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      deleting: false,
      restoring: false,
    };
  },
  computed: {
    isAdmin() {
      return this.userStore.isAdmin && this.adminProfessionalStore.showAsAdmin;
    },
    scoreInfo() {
      if (this.score?.id) {
        return this.adminProfessionalStore.scoreInfoById(this.score.id);
      }
      return undefined;
    },
    imageSrc() {
      if (!this.score?.customer?.id || this.score?.permission === false) {
        return defaultCustomerUrl;
      }
      return this.$helpers.getProfilePictureThumbnailUrl(
        this.score.customer.profilePhotoId
      );
    },
    customerName() {
      return this.score?.customer?.name && this.score?.permission !== false
        ? this.score.customer.name
        : this.$t("scoreCard.anonymizedName");
    },
  },
  methods: {
    noImage(e) {
      e.target.src = defaultCustomerUrl;
    },
    async deleteScore() {
      try {
        this.deleting = true;
        await this.adminProfessionalStore.deleteScore(this.score.id);
      } catch (err) {
        if (typeof err === "string") {
          this.$buefy.toast.open({
            message: err,
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t("admin.score.deleteError"),
            type: "is-danger",
          });
        }
      } finally {
        this.deleting = false;
      }
    },
    async restoreScore() {
      try {
        this.restoring = true;
        await this.adminProfessionalStore.restoreScore(this.score.id);
      } catch (err) {
        if (typeof err === "string") {
          this.$buefy.toast.open({
            message: err,
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t("admin.score.restoreError"),
            type: "is-danger",
          });
        }
      } finally {
        this.restoring = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.score-what {
  display: block;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
}

.score-reply {
  display: block;
  white-space: pre-wrap;
}

.score-answer {
  padding-left: 20px !important;
  border-left: 4px solid #50acfc !important;
  border-radius: 0;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.score-media {
  display: grid;
  grid-template-areas: "pic comment rating";
  grid-template-columns: auto 1fr auto;
  gap: 1rem;

  @include global.mobile {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "pic rating"
      "comment comment";
  }
}

.score-title {
  color: #3397db;
}

.score-right {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;

  @include global.mobile {
    align-items: flex-end;
  }
}

.image-rounded {
  border-radius: 50%;
}

.has-no-margin-bottom {
  margin-bottom: 0 !important;
}
</style>
