<template>
  <JoszakiPage
    :inner-class-object="{
      'grid gap-5 w-full items-start md:grid-cols-[300px_1fr]': person,
      'lg:grid-cols-[300px_1fr]': true,
    }"
    :tracking-params="trackingParams"
    :page-name="pageName"
  >
    <JoszakiLoading
      v-if="personLoading"
      :active="personLoading"
      :is-full-page="true"
    />
    <template v-else-if="person">
      {{/*AB tesztes adatlap - CSAK MOBIL*/}}
      <div v-if="false" class="md:gap-4 lg:gap-12 md:grid-cols-[1fr_300px]">
        <CardPersonActiveIndicator :person="person">
          <ProfileTop
            :person="person"
            class="w-full"
            @requestTender="onRequestTender({ source: $event })"
          />
        </CardPersonActiveIndicator>
        <div class="md:mt-9">
          <BoxTender :person="person" class="hidden md:block h-fit" />

          <ProfileInteraction
            v-if="person && !$branding.isSuperprofikCz"
            :person="person"
            class="max-md:mt-1 md:mt-4"
          />
        </div>

        <div
          class="fixed md:hidden grid grid-cols-2 bottom-0 left-0 right-0 px-2.5 py-3 bg-white z-10 border-t border-solid border-gray-200 gap-2"
        >
          <JoszakiButton
            type="error"
            size="sm"
            :expanded="true"
            @click="onRequestTender({ source: 'sticky' })"
          >
            <div class="flex-1 flex flex-col justify-between">
              <div class="font-bold text-xl">
                <IconComponent icon="comments" class="inline" />
                Ajánlatkérés
              </div>
              <div class="text-xs">
                {{ $t("sticky.oneMinAndFree") }}
              </div>
            </div>
          </JoszakiButton>
          <JoszakiButton
            type="info"
            size="sm"
            :expanded="true"
            :href="$helpers.getPhoneNumberLink(person.publicPhoneNumber)"
            @click="onClickPhoneNumber"
          >
            <div class="flex-1 flex flex-col justify-between">
              <div class="font-bold text-xl">
                <IconComponent icon="phone-alt" class="inline" />
                Hívás
              </div>
              <div class="text-xs">
                {{ $t("sticky.callMeControl") }}<br />
                {{ $helpers.formatPhoneNumber(person.publicPhoneNumber) }}
              </div>
            </div>
          </JoszakiButton>
        </div>
      </div>

      {{/*Eredeti adatlap*/}}
      <div class="sticky-col">
        <div v-if="person.isActive" class="text-white active-indicator">
          <b-icon icon="circle" class="text-xs blink" />
          <span class="uppercase">{{ $t("personProfile.openToOffers") }}</span>
        </div>
        <CardProfilePerson
          v-if="person"
          :person="person"
          @requestTender="onRequestTender({ source: 'profileCard' })"
        />
        <ProfileInteraction
          v-if="person && !$branding.isSuperprofikCz"
          :person="person"
          class="profile-interaction"
        />
      </div>

      {{/* Original profile body */}}
      <div class="profile-body">
        <div v-if="person.labels" class="flex justify-between items-center">
          <div class="flex flex-row gap-2 flex-wrap">
            <PersonBadge
              v-for="badge in person.labels"
              :key="badge.name"
              :name="badge.name"
              :description="badge.description"
              class="font-extrabold !text-base mr-2"
            />
          </div>
          <div
            v-if="!isFavoritesDisabled"
            class="!text-xl cursor-pointer"
            @click.prevent.stop="toggleLike"
          >
            <IconComponent v-if="isLiked" class="text-red-500" icon="heart" />
            <IconComponent v-else pack="far" icon="heart" />
          </div>
        </div>

        <!-- Memo -->
        <section class="profile-section">
          <h1 class="section-title">
            {{ person.name }}
          </h1>
          <p v-if="topRankedProfessions?.length" class="font-bold">
            <IconComponent icon="briefcase" class="inline mr-1" />
            <template v-for="(profession, index) in topRankedProfessions">
              {{ profession.name }}
              {{ index !== topRankedProfessions.length - 1 ? " · " : "" }}
            </template>
          </p>
          <div
            class="section-card card"
            :class="{ limitedHeight: !memoExpanded }"
          >
            <span ref="personMemo" class="card-content memo">
              {{ person.memo }}
            </span>
            <div
              v-if="!memoExpanded"
              class="showMore"
              @click="memoExpanded = true"
            >
              {{ $t("personProfile.memo.showMore") }}
              <b-icon icon="plus" />
            </div>
          </div>
        </section>

        <StickyPanels
          :person="person"
          class="sticky is-hidden-mobile"
          @requestTender="onRequestTender({ source: 'sticky' })"
        />

        <section
          v-if="person.prices && person.prices.length"
          class="profile-section"
        >
          <h1 class="section-title">
            {{ $t("personProfile.prices.title") }}
          </h1>
          <CardProfilePrices class="section-card" />
          <div class="section-subtext">
            <i18n path="personProfile.prices.accuratePrices">
              <template #offerLink>
                <a @click="onRequestTender({ source: 'prices' })">
                  {{ $t("personProfile.prices.requestOffer") }}
                </a>
              </template>
            </i18n>
          </div>
        </section>

        <!-- Photos -->
        <section v-if="person.photos?.length" class="profile-section">
          <h1 class="section-title">
            {{ $t("personProfile.references.title") }}
          </h1>
          <PersonReferences :photos="person.photos" class="section-card" />
          <div class="section-subtext">
            <i18n path="personProfile.references.likeReferences">
              <template #offerLink>
                <a @click="onRequestTender({ source: 'photos' })">
                  {{ $t("personProfile.references.requestOffer") }}
                </a>
              </template>
            </i18n>
          </div>
        </section>

        <!-- Scores -->
        <section
          v-if="person.scores?.length"
          id="ratings"
          class="profile-section"
        >
          <h1 class="section-title">
            {{ $t("personProfile.reviews.title") }}
          </h1>
          <CardScore
            v-for="(score, index) in activeProfessionScores"
            :key="`active-${index}`"
            :score="score"
            class="section-card"
          />
          <div v-if="inactiveProfessionScores.length" class="items-center flex">
            <span class="flex-grow border-t border-gray-400" />
            <span class="flex-shrink mx-2 text-gray-400">
              {{ $t("personProfile.reviews.inactiveProfessions") }}
            </span>
            <span class="flex-grow border-t border-gray-400" />
          </div>
          <CardScore
            v-for="(score, index) in inactiveProfessionScores"
            :key="`inactive-${index}`"
            :score="score"
            class="section-card !opacity-50"
          />
          <template v-if="isAdmin && privateData?.deletedScores?.length">
            <CardScore
              v-for="(score, index) in privateData.deletedScores"
              :key="`deleted-${index}`"
              :score="score"
              class="section-card !opacity-50"
            />
          </template>
          <div class="section-subtext">
            <i18n path="personProfile.reviews.becomeReviewer">
              <template #offerLink>
                <a @click="onRequestTender({ source: 'reviews' })">
                  {{ $t("personProfile.reviews.requestOffer") }}
                </a>
              </template>
            </i18n>
          </div>
        </section>

        <!-- Answers -->
        <section v-if="person.topAnswers?.length" class="profile-section">
          <h1 class="section-title">
            {{ $t("personProfile.answers.title") }}
          </h1>
          <AnswerInset
            v-for="answer in person.topAnswers"
            :key="answer.id"
            :answer="answer"
            :is-own-profile="true"
          />
        </section>
        <!--</template> -->

        <!-- Professions -->
        <PersonProfessionTags class="profile-section"></PersonProfessionTags>
      </div>

      <StickyPanels
        :person="person"
        class="sticky-mobile"
        @requestTender="onRequestTender({ source: 'sticky' })"
      />

      <ReportPersonModal />
    </template>
  </JoszakiPage>
</template>

<router>
{
  name: 'professionalPage'
}
</router>

<script>
import { mapState } from "pinia";
import { useAsync, useContext, useRoute } from "@nuxtjs/composition-api";
import REDIRECT from "~/stores/admin/queries/redirect.graphql"; // TODO: remove once redirect rework is merged
import NunitoRegular from "~/assets/fonts/nunito-regular.woff2";
import NunitoSemibold from "~/assets/fonts/nunito-semibold.woff2";
import NunitoBold from "~/assets/fonts/nunito-bold.woff2";
import NunitoExtrabold from "~/assets/fonts/nunito-extrabold.woff2";

import { generatePreloadLinks } from "~/helpers/files";

import AnswerInset from "~/components/_refactored/profile/AnswerInset.vue";
import PersonBadge from "~/components/_refactored/profile/PersonBadge.vue";
import CardProfilePrices from "~/components/_refactored/profile/CardProfilePrices.vue";
import CardProfilePerson from "~/components/profile/CardProfilePerson.vue";
import PersonReferences from "~/components/profile/PersonReferences.vue";
import ProfileInteraction from "~/components/profile/ProfileInteraction.vue";
import ReportPersonModal from "~/components/profile/ReportPersonModal.vue";
import CardScore from "~/components/profile/CardScore.vue";
import StickyPanels from "~/components/profile/StickyPanels.vue";

import { useRequestTender } from "~/composables/requestTender";
import BoxTender from "~/components/_refactored/app/boxes/BoxTender.vue";
import ProfileTop from "~/components/_refactored/profile/ProfileTop.vue";
import CardPersonActiveIndicator from "~/components/_refactored/card/CardPersonActiveIndicator.vue";
import { useProfessionalStore } from "~/stores/professional";
import { useAdminProfessionalStore } from "~/stores/admin/professional";
import { useUserStore } from "~/stores/user";
import { EVENT_PAGE_NAMES } from "~/data/pageNames";
import { useNewTender } from "~/composables/tender/useNewTender";
import IS_PHONE_MISMATCH from "~/components/_refactored/card/isPhoneMismatch.graphql";
import PersonProfessionTags from "~/components/profile/PersonProfessionTags.vue";
import { useSettingsStore } from "~/stores/settings";

export default {
  name: "ProfessionalPage",
  components: {
    AnswerInset,
    CardProfilePerson,
    PersonBadge,
    StickyPanels,
    PersonReferences,
    CardScore,
    ProfileInteraction,
    CardProfilePrices,
    ReportPersonModal,
    BoxTender,
    ProfileTop,
    CardPersonActiveIndicator,
    PersonProfessionTags,
  },
  layout: "AdminLayout",
  setup() {
    try {
      const professionalStore = useProfessionalStore();
      const newTender = useNewTender();

      useAsync(async () => {
        const route = useRoute();
        newTender.hideFloater();

        const seoName = route.value.params.professionalSeoName;

        // TODO: remove once redirect rework is merged
        const { redirect, $query, localePath } = useContext();
        const { redirect: redirectedSeoName } = await $query(REDIRECT, {
          seoName,
        });
        if (redirectedSeoName && redirectedSeoName !== seoName) {
          redirect(
            localePath({
              name: "professionalPage",
              params: { professionalSeoName: redirectedSeoName },
            })
          );
        }

        await professionalStore.loadPerson(seoName);
      });
      return {
        pageName: EVENT_PAGE_NAMES.PROFESSIONAL_PAGE,
        professionalStore,
        newTender,
        adminProfessionalStore: useAdminProfessionalStore(),
        userStore: useUserStore(),
        ...useRequestTender(),
      };
    } catch (err) {
      console.error(err);
      return {};
    }
  },
  data() {
    return {
      memoExpanded: false,
      isLiked: false,
      trackingParams: null,
    };
  },
  head() {
    if (!this.person) {
      return;
    }
    const title = `${this.person.name} - ${this.person.professions
      .map((p) => p.name.charAt(0) + p.name.slice(1))
      .sort()
      .join(", ")} - ${this.person.city.name}`;
    const description =
      this.person.memo && this.person.memo.length > 128
        ? `${this.person.memo.slice(0, 125)}...`
        : this.person.memo ?? "";
    const head = {
      title,
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(this.jsonld), // <- set jsonld object in data or wherever you want
        },
      ],
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: `${this.$helpers.topDomain}${this.$route.path}`,
        },
        {
          hid: "og:image",
          property: "og:image",
          content: `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_BUCKET}/${this.person.id}`,
        },
        {
          hid: "description",
          name: "description",
          content: description,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: description,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: process.env.TOP_DOMAIN + this.$route.path,
        },
        ...generatePreloadLinks([
          NunitoRegular,
          NunitoSemibold,
          NunitoBold,
          NunitoExtrabold,
        ]),
      ],
    };

    if (this.person.excluded) {
      head.meta.push({
        hid: "robots",
        name: "robots",
        content: "noindex",
      });
    }

    return head;
  },
  computed: {
    ...mapState(useSettingsStore, {
      isFavoritesDisabled: "isFavoritesDisabled",
    }),
    ...mapState(useProfessionalStore, {
      person: "person",
      personLoading: "personLoading",
      city: "city",
      profession: "profession",
      rankedProfessions: "rankedProfessions",
      activeProfessionScores: "activeProfessionScores",
      inactiveProfessionScores: "inactiveProfessionScores",
    }),
    ...mapState(useAdminProfessionalStore, {
      privateData: "privateData",
    }),
    jsonld() {
      return {
        "@context": "https://schema.org",
        "@type": "ProfessionalService",
        address: {
          "@type": "PostalAddress",
          addressLocality: this.city?.name,
          addressCountry: "HU",
        },
        reviewRating: {
          "@type": "Rating",
          ratingValue: this.person.score / 2,
        },
        name:
          this.person.name.replace(/["'/<>]/gi, "") +
          " - " +
          this.person.professions.map((p) => p.name).join(", "),
        telephone: this.$helpers.formatPhoneNumber(
          this.person.publicPhoneNumber
        ),
        url: this.$router.fullPath,
      };
    },
    isAdmin() {
      return this.userStore.isAdmin;
    },
    topRankedProfessions() {
      return this.rankedProfessions?.slice(0, 3) ?? [];
    },
    mainProfession() {
      return this.rankedProfessions?.[0];
    },
  },
  watch: {
    isAdmin(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.adminProfessionalStore.loadScoreInfo();
      }
    },
  },
  beforeMount() {
    this.trackingParams = {
      personId: this.person?.id,
      personSeoName: this.person?.seoName,
      urlSeoName: this.$route.params.professionalSeoName,
      hasMemo: !!this.person?.memo,
      professionSeoName: this.profession?.seoName,
      professionGroupSeoName: this.profession?.professionGroup?.seoName,
      citySeoName: this.city?.seoName,
    };
  },
  mounted() {
    if (this.person && this.$refs.personMemo) {
      this.isLiked = this.$store.getters["localStorage/isLikedPerson"](
        this.person.id
      );
      const clientRect = this.$refs.personMemo.getBoundingClientRect();

      this.memoExpanded = clientRect.height < 150;
      this.$cookie.remove("open-profile");

      this.newTender.prefill({
        profession: this.profession,
        city: this.city,
        mate: this.person,
      });

      this.trackViewItem();

      this.seoTrackProfileView();

      if (this.person.publicPhoneNumber?.number) {
        this.$query(IS_PHONE_MISMATCH, {
          personId: this.person.id,
          phone: this.person.publicPhoneNumber.number,
        })
          .then((res) => {
            if (res.isPhoneMismatch) {
              this.$sentry.captureMessage(
                `Phone mismatch for person ${this.person.id}, phone: ${this.person.publicPhoneNumber.number} url: ${window.location.href}`,
                {
                  level: "fatal",
                }
              );
            }
          })
          .catch((err) => {
            console.error(err, "Failed to query phone mismatch");
          });
      }
    }

    if (this.person && this.isAdmin) {
      this.adminProfessionalStore.loadPrivateData();
    }
  },
  methods: {
    toggleLike() {
      this.isLiked = !this.isLiked;
      this.$trackers.trackEvent({
        title: "Person Favorited",
        data: {
          personId: this.person.id,
          liked: this.isLiked,
        },
      });
      if (this.isLiked) {
        this.$store.dispatch("localStorage/favoritePerson", this.person.id);
      } else {
        this.$store.dispatch("localStorage/unfavoritePerson", this.person.id);
      }
      this.$emit("like", {
        personId: this.person.id,
        liked: this.isLiked,
      });
    },
    trackViewItem() {
      if (!process.server) {
        this.$track.customEvent({ ecommerce: null });
        this.$track.customEvent({
          event: "view_item",
          source_page: "profile",
          city_id: this.city?.Id,
          profession_id: this.profession?.id,
          ecommerce: {
            items: [
              {
                index: 0,
                item_id: this.person?.id,
                item_name: this.person?.name,
                item_list_name: `${this.profession?.name} ${this.city?.name}`,
                item_list_id: `${this.city?.seoName}#${this.profession?.seoName}`,
              },
            ],
          },
        });
      }
    },
    seoTrackProfileView() {
      this.$track.customEvent({
        event: "profileView",
        profession: this.mainProfession?.seoName, // elsődleges szakma
        location: this.person.city.seoName, // település
        registrationDate: this.person.created,
        lastLogin: this.person.lastLogin,
        descWordCount: this.person.memo?.length
          ? this.person.memo.split(" ").length
          : 0, // leírás hossza szavakban
        descCharCount: this.person.memo?.length || 0, // leírás hossza karakterekben
        orders: this.person.winCount, // megbízások száma
        reviews: this.person.scores.length, // vélemények száma
        score: this.person.score / 2, // értékelés átlaga
        hasPhoto: this.$store.getters["professional/hasPicture"], // boolean -> 1 = van fotó, 0 = nincs fotó
      });
    },
    seoTrackProfileTender() {
      this.$track.customEvent({
        event: "beginRequestProposal",
        profession: this.mainProfession?.seoName, // elsődleges szakma
        allProfessions: this.person?.professions
          ?.map((x) => x.seoName)
          .join(","),
        location: this.person.city.seoName, // település
        registrationDate: this.person.created,
        lastLogin: this.person.lastLogin,
        descWordCount: this.person.memo?.length
          ? this.person.memo.split(" ").length
          : 0, // leírás hossza szavakban
        descCharCount: this.person.memo?.length || 0, // leírás hossza karakterekben
        orders: this.person.winCount, // megbízások száma
        reviews: this.person.scores.length, // vélemények száma
        score: this.person.score / 2, // értékelés átlaga
        hasPhoto: this.$store.getters["professional/hasPicture"], // boolean -> 1 = van fotó, 0 = nincs fotó
      });
    },
    seoTrackQuickCall() {
      this.$track.customEvent({
        event: "clickCall",
        profession: this.mainProfession?.seoName, // elsődleges szakma
        allProfessions: this.person?.professions
          ?.map((x) => x.seoName)
          .join(","),
        location: this.person.city.seoName, // település
        registrationDate: this.person.created,
        lastLogin: this.person.lastLogin,
        descWordCount: this.person.memo?.length
          ? this.person.memo.split(" ").length
          : 0, // leírás hossza szavakban
        descCharCount: this.person.memo?.length || 0, // leírás hossza karakterekben
        orders: this.person.winCount, // megbízások száma
        reviews: this.person.scores.length, // vélemények száma
        score: this.person.score / 2, // értékelés átlaga
        hasPhoto: this.$store.getters["professional/hasPicture"], // boolean -> 1 = van fotó, 0 = nincs fotó
      });
    },
    onRequestTender({ source }) {
      this.$track.analyticsEvent({
        category: "adatlap",
        action: "kattintas-ajanlatkeresre",
        label: source,
      });

      this.requestTender({
        person: this.person,
        profession: this.profession,
        city: this.city,
        source,
      });
    },
    onClickPhoneNumber() {
      this.$trackers.trackEvent({
        title: "Clicked on Phone Number on Profile",
        data: {
          personId: this.person?.id,
          personSeoName: this.person?.seoName,
        },
      });
      this.$track.customEvent({
        event: "initiate_call",
        ecommerce: { items: [this.person.id] },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.memo {
  display: block;
  white-space: pre-wrap;
  overflow: auto;
}

.profile-body {
  width: 100%;
}

.card {
  border-radius: 1rem;
  overflow: hidden;
}

// .profile-body > .profile-section:first-of-type {
//   margin-top: 0rem;
// }

.profile-section {
  width: 100%;
  margin: 1.5rem 0;
}

.column > .profile-section:first-of-type {
  margin-top: 0;
}

.section-title {
  color: #2e72ad;
  margin-bottom: 0.8rem;
  font-weight: 700;
  font-size: 2rem;
}

.pandemic-sticker-wrapper {
  height: 70px;
  width: 100%;
  text-align: center;

  .pandemic-sticker {
    height: 100%;
  }
}

.sticky {
  margin: 1rem 0;
}

.sticky-mobile {
  display: none;

  @include global.mobile {
    display: block;
    position: sticky;
    bottom: 0;
    background: white;
    width: 100%;
    height: max-content;
    padding: 0.5rem 0.5rem 40px;
  }
}

.section-card {
  margin: 1rem 0;
  overflow: visible;
  width: 100%;
}

.limitedHeight {
  max-height: 150px;
  overflow: hidden;
}

.showMore {
  position: absolute;
  bottom: 0px;
  z-index: 2;
  left: 0px;
  padding: 3px 5px;
  padding-left: 15px;
  background: #ddd;
  color: #2e72ad;
  user-select: none;
  border-radius: 0 5px 0 0;

  &:hover {
    background: #2e72ad;
    color: white;
    cursor: pointer;
  }
}

.section-subtext {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky-col {
  position: sticky;

  @include global.mobile {
    position: relative;
  }

  top: 1rem;
  margin-top: -1rem;
}

.active-indicator {
  background: #00c836;
  border-radius: 1rem;
  height: 4rem;
  margin-bottom: -2rem;
  width: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;

  > .icon {
    position: relative;
    top: -2px;
  }
}

.profile-interaction {
  margin-top: 2rem;
}

.profession-wincount {
  font-size: 0.7rem;
  margin-left: -0.3rem;
  color: #74b8e6;
}
</style>
