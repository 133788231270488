var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card references"},[_c('nav',{staticClass:"navbar photo-bar"},[_c('div',{staticClass:"navbar-menu is-active"},[_c('div',{staticClass:"navbar-start"},[_vm._l((_vm.visibleTabs),function(tab){return _c('a',{key:tab.id,staticClass:"navbar-item is-tab",class:{
            'is-active': _vm.activeTabIndex === tab.id,
          },on:{"click":function($event){_vm.activeTabIndex = tab.id}}},[_vm._v(" "+_vm._s(tab.label)+" ")])}),(_vm.dropdownTabs.length)?_c('div',{staticClass:"navbar-item has-dropdown is-hoverable",class:{
            active: _vm.dropdownTabIds.includes(_vm.activeTabIndex),
          }},[_c('a',{staticClass:"navbar-link is-arrowless",class:{
              expanded: _vm.expanded,
            },on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_vm._v(" "+_vm._s(_vm.$t("personReferences.otherProfessions"))+" "),_c('IconComponent',{staticClass:"ml-2",attrs:{"icon":"angle-down"}})],1),_c('div',{staticClass:"navbar-dropdown",class:{
              'hidden-mobile': !_vm.expanded,
            }},_vm._l((_vm.dropdownTabs),function(tab){return _c('a',{key:tab.id,staticClass:"navbar-item",class:{
                'is-active': _vm.activeTabIndex === tab.id,
              },on:{"click":function($event){_vm.expanded = false;
                _vm.activeTabIndex = tab.id;}}},[_vm._v(" "+_vm._s(tab.label)+" ")])}),0)]):_vm._e()],2)])]),(_vm.activeTab)?_c('ImageGallery',{staticClass:"photo-gallery",attrs:{"images":_vm.activeTab.photos,"type":"reference-photo"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }