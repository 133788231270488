<template>
  <div v-if="!score.deleted || isAdmin" class="min-h-[6rem] md:min-h-[9rem]">
    <div class="flex gap-4">
      <div class="flex justify-center">
        <PersonPictureSet
          :class="[
            'rounded-full overflow-hidden w-20 h-20 block flex-0',
            'md:w-32 md:h-32',
          ]"
          :person="score.customer"
          :alt="score.customer.name"
        />
      </div>
      <div class="flex-1 flex flex-col gap-2 md:gap-4">
        <div class="flex justify-between">
          <div
            class="flex flex-col md:flex-row items-start md:items-center gap-1 md:gap-3 h-fit"
          >
            <!-- TODO change from buefy components -->
            <b-tooltip
              v-if="isAdmin && scoreInfo && scoreInfo.customer"
              multilined
              dashed
            >
              <template #content>
                <b v-if="scoreInfo.customer?.phoneNumber"
                  >Mobile:
                  {{
                    $helpers.formatPhoneNumber(scoreInfo.customer.phoneNumber)
                  }}</b
                ><br />
                Email: {{ scoreInfo.customer.email }}
              </template>
              <a
                v-if="scoreInfo.tenderId"
                class="score-title text-xl mr-2"
                :href="`${$helpers.phpDomain}/operator/tender/id/${scoreInfo.tenderId}`"
                target="_blank"
              >
                {{ customerName }}
              </a>
              <b v-else class="score-title text-xl mr-2">
                {{ customerName }}
              </b>
            </b-tooltip>
            <div v-else class="text-primary">
              {{ customerName }}
            </div>
            <div class="text-xs text-gray-400">
              {{ $d(new Date(score.created), "numericDateTime") }}
            </div>
            <template v-if="isAdmin">
              <p v-if="score.deleted" class="text-error font-bold">
                {{ $t("scoreCard.deleted") }}
              </p>
              <JoszakiButton
                v-if="score.deleted"
                :loading="restoring"
                @click="restoreScore"
              >
                {{ $t("scoreCard.restore") }}
              </JoszakiButton>
              <JoszakiButton v-else :loading="deleting" @click="deleteScore">
                {{ $t("common.delete") }}
              </JoszakiButton>
            </template>
          </div>
          <div class="flex justify-center items-center h-fit">
            <RatingIndicator
              :rate="score.score / 2"
              :disabled="true"
              class="rating-stars"
            />
            <!-- <span class="font-bold after:[content:'·'] after:ml-1">{{ halfScore.toFixed(1) }}</span>
            <IconComponent
              pack="fas"
              icon="star"
              class="text-amber-400"
            /> -->
          </div>
        </div>
        <p class="text-sm col-span-2" v-text="score.what" />
        <div v-if="score.answer && !score.answer.deleted" class="">
          <div
            class="flex flex-col border-l-2 border-solid border-primary-light bg-primary-lightest/10 pl-2"
          >
            <p class="text-primary">
              {{ $t("scoreCard.mateReply") }}
            </p>
            <p class="">
              {{ score.answer.answer }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RatingIndicator from "~/components/people/RatingIndicator.vue";
import PersonPictureSet from "~/components/PersonPictureSet.vue";

import { useAdminProfessionalStore } from "~/stores/admin/professional";
import { useUserStore } from "~/stores/user";

export default {
  components: {
    PersonPictureSet,
    RatingIndicator,
  },
  props: {
    score: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      adminProfessionalStore: useAdminProfessionalStore(),
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      deleting: false,
      restoring: false,
    };
  },
  computed: {
    isAdmin() {
      return this.userStore.isAdmin && this.adminProfessionalStore.showAsAdmin;
    },
    scoreInfo() {
      if (this.score?.id) {
        return this.adminProfessionalStore.scoreInfoById(this.score.id);
      }
      return undefined;
    },
    customerName() {
      return this.score?.customer?.name && this.score?.permission !== false
        ? this.score.customer.name
        : this.$t("scoreCard.anonymizedName");
    },
    halfScore() {
      return this.score.score / 2;
    },
  },
  methods: {
    async deleteScore() {
      try {
        this.deleting = true;
        await this.adminProfessionalStore.deleteScore(this.score.id);
      } catch (err) {
        if (typeof err === "string") {
          this.$buefy.toast.open({
            message: err,
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: this.$t("admin.score.deleteError"),
            type: "is-danger",
          });
        }
      } finally {
        this.deleting = false;
      }
    },
    async restoreScore() {
      try {
        this.restoring = true;
        await this.adminProfessionalStore.restoreScore(this.score.id);
      } catch (err) {
        if (typeof err === "string") {
          this.$buefy.toast.open({
            message: err,
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            type: "is-danger",
          });
        }
      } finally {
        this.restoring = false;
      }
    },
  },
};
</script>
