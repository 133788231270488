<template>
  <picture>
    <source ref="source" type="image/webp" v-lazy-load :data-srcset="getWebpSrcset" />
    <img
      ref="img"
      :width="width"
      :height="height"
      v-lazy-load :data-src="getFallback"
      :sizes="getSizes"
      v-lazy-load :data-srcset="getJpgSrcset"
      :alt="alt"
      @error="onError"
    />
  </picture>
</template>

<script>
import defaultAvatar from "~/assets/default-avatar.jpg";

export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      sourceWidths: [112, 128, 192, 256, 336, 448],
    };
  },
  computed: {
    getFallback() {
      if (!this.person?.profilePhotoId) {
        return defaultAvatar;
      }
      return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_BUCKET}/${this.person.profilePhotoId}`; // originally uploaded, unconverted image
    },
    getSizes() {
      return "(max-width: 419px) 64px, 112px";
    },
    getWebpSrcset() {
      if (!this.person?.profilePhotoId) {
        return defaultAvatar;
      }
      return this.sourceWidths.map((size) => {
        return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_CONVERTED_BUCKET}/${this.person.profilePhotoId}_${size}.webp ${size}w`;
      });
    },
    getJpgSrcset() {
      if (!this.person?.profilePhotoId) {
        return defaultAvatar;
      }
      return this.sourceWidths
        .map((size) => {
          return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_CONVERTED_BUCKET}/${this.person.profilePhotoId}_${size}.jpg ${size}w`;
        })
        .join(", ");
    },
  },
  methods: {
    onError() {
      try {
        this.$refs.source.srcset = "";
        this.$refs.img.srcset = "";
        this.$refs.img.src = defaultAvatar;
        this.$emit("error");
      } catch (error) {
        console.error("personPictureSet.onError", error);
        this.$sentry.captureException(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  display: block;
  object-fit: cover;
}
</style>
