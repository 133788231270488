var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.score.deleted || _vm.isAdmin)?_c('div',{staticClass:"card",class:{
    '!bg-gray-300': _vm.isAdmin && _vm.scoreInfo && _vm.scoreInfo.originalTargetPerson,
  }},[_c('div',{staticClass:"card-content"},[_c('article',{staticClass:"score-media"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"image is-64x64 image-rounded",staticStyle:{"grid-area":"pic"},attrs:{"data-src":_vm.imageSrc},on:{"error":_vm.noImage}}),_c('div',{staticClass:"content has-no-margin-bottom",staticStyle:{"grid-area":"comment"}},[_c('div',{staticClass:"flex items-center",staticStyle:{"flex-wrap":"wrap"}},[(_vm.isAdmin && _vm.scoreInfo && _vm.scoreInfo.customer)?_c('b-tooltip',{attrs:{"multilined":"","dashed":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.scoreInfo.customer?.phoneNumber)?_c('b',[_vm._v(" Mobile: "+_vm._s(_vm.$helpers.formatPhoneNumber(_vm.scoreInfo.customer.phoneNumber))+" ")]):_vm._e(),_c('br'),_vm._v(" Email: "+_vm._s(_vm.scoreInfo.customer.email)+" ")]},proxy:true}],null,false,3882963332)},[(_vm.scoreInfo.tenderId)?_c('a',{staticClass:"score-title text-xl mr-2",attrs:{"href":`${_vm.$helpers.phpDomain}/operator/tender/id/${_vm.scoreInfo.tenderId}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerName)+" ")]):_c('b',{staticClass:"score-title text-xl mr-2"},[_vm._v(" "+_vm._s(_vm.customerName)+" ")])]):_c('b',{staticClass:"score-title text-xl mr-2"},[_vm._v(" "+_vm._s(_vm.customerName)+" ")])],1),(_vm.score.profession)?_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.score.profession.name)+" · ")]):_vm._e(),_c('time',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.$d( new Date(_vm.score.created), _vm.isAdmin ? "numericDateTime" : "numericDate" ))+" ")]),(_vm.isAdmin && _vm.scoreInfo && _vm.scoreInfo.originalTargetPerson)?_c('div',[_c('NuxtLink',{staticClass:"text-info hover:text-info-darker underline",attrs:{"to":_vm.localePath({
                name: 'professionalPage',
                params: {
                  professionalSeoName: _vm.scoreInfo.originalTargetPerson.seoName,
                },
              })}},[_vm._v(" "+_vm._s(_vm.$t("scoreCard.originalScoreTarget"))+" ")])],1):_vm._e(),_c('span',{staticClass:"score-what",domProps:{"textContent":_vm._s(_vm.score.what)}}),(_vm.score.answer)?_c('article',{staticClass:"message is-info"},[_c('div',{staticClass:"message-body score-answer"},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t("scoreCard.mateReply"))+" ")]),_c('span',{staticClass:"score-reply",domProps:{"textContent":_vm._s(_vm.score.answer.answer)}})])]):_vm._e()]),_c('div',{staticClass:"score-right",staticStyle:{"grid-area":"rating"}},[_c('b-rate',{attrs:{"icon-pack":"fas","value":_vm.score.score / 2,"disabled":true}}),(_vm.isAdmin)?_c('div',{staticClass:"flex flex-col gap-1"},[(_vm.score.deleted)?_c('p',{staticClass:"text-error font-bold"},[_vm._v(" "+_vm._s(_vm.$t("scoreCard.deleted"))+" ")]):_vm._e(),(_vm.score.deleted)?_c('JoszakiButton',{attrs:{"type":"warning","loading":_vm.restoring},on:{"click":_vm.restoreScore}},[_vm._v(" "+_vm._s(_vm.$t("scoreCard.restore"))+" ")]):_c('JoszakiButton',{attrs:{"type":"error","loading":_vm.deleting},on:{"click":_vm.deleteScore}},[_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")]),_c('JoszakiButton',{attrs:{"to":_vm.localePath({
                name: 'conductorRatingsEditPage',
                params: { id: _vm.score.id },
              }),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")])],1):_vm._e()],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }