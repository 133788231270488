<template>
  <div class="card references">
    <nav class="navbar photo-bar">
      <div class="navbar-menu is-active">
        <div class="navbar-start">
          <a
            v-for="tab in visibleTabs"
            :key="tab.id"
            class="navbar-item is-tab"
            :class="{
              'is-active': activeTabIndex === tab.id,
            }"
            @click="activeTabIndex = tab.id"
          >
            {{ tab.label }}
          </a>
          <div
            v-if="dropdownTabs.length"
            class="navbar-item has-dropdown is-hoverable"
            :class="{
              active: dropdownTabIds.includes(activeTabIndex),
            }"
          >
            <a
              class="navbar-link is-arrowless"
              :class="{
                expanded: expanded,
              }"
              @click="expanded = !expanded"
            >
              {{ $t("personReferences.otherProfessions") }}

              <IconComponent class="ml-2" icon="angle-down" />
            </a>

            <div
              class="navbar-dropdown"
              :class="{
                'hidden-mobile': !expanded,
              }"
            >
              <a
                v-for="tab in dropdownTabs"
                :key="tab.id"
                class="navbar-item"
                :class="{
                  'is-active': activeTabIndex === tab.id,
                }"
                @click="
                  expanded = false;
                  activeTabIndex = tab.id;
                "
              >
                {{ tab.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <ImageGallery
      v-if="activeTab"
      :images="activeTab.photos"
      type="reference-photo"
      class="photo-gallery"
    />
  </div>
</template>

<script>
import ImageGallery from "../global/ImageGallery.vue";

export default {
  components: { ImageGallery },
  props: {
    photos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [],
      expanded: false,
    };
  },
  computed: {
    activeTab() {
      return this.tabs.find((x) => x.id === this.activeTabIndex);
    },
    visibleTabs() {
      return this.tabs.slice(0, 2);
    },
    dropdownTabs() {
      return this.tabs.slice(2);
    },
    dropdownTabIds() {
      return this.dropdownTabs.map((x) => x.id);
    },
  },
  created() {
    const tabMap = new Map();
    const profession = this.$route.query?.profession;
    for (const photo of this.photos) {
      const profId = photo.profession?.id || "-1";
      const label =
        photo.profession?.name || this.$t("personReferences.generic");
      let tab = tabMap.get(profId);
      if (!tab) {
        tab = {
          id: profId,
          profession: photo.profession?.seoName || "generic",
          label,
          photos: [],
        };
        tabMap.set(profId, tab);
      }
      tab.photos.push({
        name: photo.name,
        description: photo.note,
      });
    }
    let priorityTab;
    tabMap.forEach((value) => {
      if (profession && profession === value.profession) {
        priorityTab = value;
      } else {
        this.tabs.push(value);
      }
    });
    this.tabs.sort(
      (a, b) => parseInt(b.photos.length) - parseInt(a.photos.length)
    );
    if (priorityTab) {
      this.tabs.unshift(priorityTab);
    }
    this.activeTabIndex = this.tabs?.[0]?.id;
  },
};
</script>

<style lang="scss" scoped>
.references {
  padding: 1rem;
}

.photo-gallery {
  padding: 2em 1em 1em;
}

.navbar-item.is-active {
  background-color: lighten(#485fc7, 40);
}

.references {
  a.navbar-item {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>

<style lang="scss">
@use "styles/_all-members" as global;

.photo-bar {
  border-bottom: 1px solid #ddd;
  z-index: 1 !important;

  .navbar-link.is-arrowless {
    .icon {
      float: right;
      transition: transform 0.4s;
      transform: rotate(0deg);
    }
  }

  .navbar-link.is-arrowless.expanded {
    .icon {
      @include global.touch {
        transform: rotate(180deg);
      }
    }
  }

  .navbar-dropdown {
    &.hidden-mobile {
      @include global.touch {
        display: none !important;
      }
    }
  }

  .active {
    .navbar-link {
      background-color: #e4e8f7 !important;
      color: #485fc7;

      border-bottom: 3px solid #485fc7;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      border-radius: 4px 4px 0 0;
    }
  }
}
</style>
